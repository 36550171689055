<template>
	<div class="products-main">
		<div class="page_banner"><img src="@/assets/images/blood-banner.jpg">
			<div class="words">
				<p>
					<span>医疗-独立血液净化中心</span>
					软件定制化服务提供商
				</p>
			</div>
		</div>
		<div class="products layout">
			<MenuLayout />
			<div class="products-contact">
				<div class="BreadCrumbs"><span>医疗</span>
					<p class="crumbs"><a>您的位置：</a>
					 <a>产品</a>> <a>独立血液净化中心</a>
					</p>
				</div>

				<div class="productsChilder">
					<div class="content">
						<p>
							<b>医护人员管理</b>
						</p>
						<p>
							医护人员信息录入,权限分级管理，安全可靠。
						</p>
						<img src="@/assets/images/products/medical/01.png">

						<br />
						<p>
							<b>患者信息管理</b>
						</p>
						<p>
							对于患者的基本信息.传染病信息等依类进行分类录入管理,医生可方便的进行查阅修改。
						</p>
						<img src="@/assets/images/products/medical/02.png">
						<br />
						<p>
							<b>药品管理</b>
						</p>
						<p>
							药品信息录入,让大夫告别患者报到的时候每次繁琐的下医嘱。
						</p>
						<img src="@/assets/images/products/medical/03.png">
						<br />
						<p>
							<b>排班管理</b>
						</p>
						<p>
							规范透析，为患者合理排班，提高生活质量，减轻医护人员压力,减少耗材损失。
						</p>
						<img src="@/assets/images/products/medical/04.png">
						<br />
						<p>
							<b>患者报到管理</b>
						</p>
						<p>
							方便简洁,高精简度操作,减少患者报到等待时间。
						</p>
						<img src="@/assets/images/products/medical/05.png">
						<br />
						<p>
							<b>患者治疗数据分析记录</b>
						</p>
						<p>
							患者每次治疗记录存档,建立完善安全可靠的个人健康档案,为患者跟踪诊疗,提供数据保障。
						</p>
						<img src="@/assets/images/products/medical/06.png">
						<br />
						<p>
							<b>医用耗材管理</b>
						</p>
						<p>
							医疗器械等耗材及血管通路类型管理。
						</p>
						<img src="@/assets/images/products/medical/07.png">
					</div>
				</div>
				<div class="productsChilder">
					<h3 class="productsChilder-title">六大功能及优势</h3>

					<ul class="solution-detail">
						<li>
							智能化科学管理，为患者实施精准治疗提供准确的医疗数据支撑。
						</li>
						<li>
							无纸化办公，医疗器械数据自动采集录入。医院现有医疗系统(如、Lis、PACS等)无缝对接.
						</li>
						<li>
							透析状态实时跟踪，依据卫生部血液净化标准操作规程SOP(2 0 0 0版)，智能化生成记录透析单)。
						</li>
						<li>
							规范化透析流程数据录入、简便操作。
						</li>
						<li>
							患者与医护人员云端问题预约解答。实现24小时实时医疗保障.
						</li>
						<li>
							为患者科学排班规范透析，同时减轻医护人员工作压力。
						</li>
					</ul>
				</div>
				<div class="productsChilder">
					<h3 class="productsChilder-title">浙江胤德信息科技有限公司介绍</h3>

					<div class="content">
						浙江胤德信息科技有限公司是一家致力于医疗信息系统研发、实施和数字乡村建设的高科技企业。目前已经完成区域全民健康信息平台解决方案、智慧医疗共体服务平台建设与应用、智能化血液净化管理系统、数字乡村综合服务体系解决方案的研发，并在多个地区和医院完成部署实施，高质稳定的运行，等到业界至高评价。区域全民健康信息平台通过云部署实现了地区（省）医疗机构的互联互通、数据共享、远程指导等；智慧医共体服务平台建设亦是通过云部署实现了各医疗机构的互联互通、医疗资源的共享；智能化血液净化管理系统为血透患者的规律透析精准化治疗提供了强有力的数据支撑；数字乡村和服务体系解决方案在全面做好十大板块内容的基础上以数字技术与农村经济深度融合为主攻方向，以数据为关键生产要素，着力建设基础数据资源体系，加强数字生产能力建设，加快数字生产能力建设，加快农业农村生产经营，管理服务数字化改造，推动政府信息系统和公共数据互联开放共享，全面提升农业农村生产智能化、经营网络化、管理高效化、服务便捷化。
					</div>
				</div>
				<div class="productsChilder">
					<h3 class="productsChilder-title">成功案例</h3>
					<div class="content">
						<ul class="caseList">
							<li v-for="item in caseList" :key="item.src">
								<div class="imgbox">
									<img :src="item.src">
								</div>
								<a class="text">
									{{ item.text }}
								</a>
							</li>

						</ul>
					</div>


				</div>
			</div>
		</div>

	</div>
</template>
<script>
import MenuLayout from "./components/menu"

export default ({
	data() {
		return {
			caseList: [
				{
					src: require('@/assets/images/case/medical/100.jpg'),
					text: "河南省鹿邑县"
				},
				{
					src: require('@/assets/images/case/medical/101.jpg'),
					text: "江西省永丰县"
				}
			]
		}
	},
	components: {
		MenuLayout
	},
	methods: {

	}
});
</script>
