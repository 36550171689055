<template>
	<div class="products-menu">
		<dl class="nav">
			<dt>产品</dt>

			<dd>
				<router-link class="title" to="/products/carbonNeutral">碳达峰、碳中和、产业链</router-link>
			</dd>
			<dd>
				<router-link class="title" to="/products/environmentaFriendly">环保</router-link>
				<div class="childer">
					<router-link to="/products/oilFumeSeparation" v-if="false">油烟分离</router-link>
					<router-link to="/products/sterilizer">等离子空气消毒器</router-link>
				</div>
			</dd>
			<dd>
				<router-link class="title" to="/products/culture">港深文化</router-link>
			</dd>
			<dd>
				<router-link class="title" to="/products/medical">医疗</router-link>
			</dd>
			<dd>
				<router-link class="title" to="/products/digitalVillage">数字乡村</router-link>
			</dd>

			<dd>
				<router-link class="title" to="/products/health">区域全民健康信息平台</router-link>
			</dd>
		</dl>
		<dl class="ltlxwm">
			<dt>联系我们</dt>
			<span>了解更多详细信息，请致电</span>
			<h2>151-3212-2977</h2>
			<!-- <p>或给我们留言</p>
			<a href="#">在线留言</a> -->
		</dl>
	</div>
</template>
<script>
export default ({
	methods: {

	}
});
</script>
